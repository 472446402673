import { Form, Checkbox, Alert, Typography } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get, post } from "../../api/Request";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../context/AuthContext";
import { Patient2 } from "../../types/Patient";
import ValueLabel from "../../components/layout/ValueLabel";
import MyModal from "../../components/MyModal";
import PhoneInput from "antd-phone-input";
const { Title, Paragraph, Text, Link } = Typography;
interface Props {
  planId: number;
  patientId: number;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

interface FormProps {
  message: string;
  phone3: { countryCode: number; phoneNumber: string; areaCode?: string };
  save_phone?: boolean;
}

const WhatsappModal: React.FC<Props> = ({
  planId,

  patientId,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { user, setUser } = useAuth();

  const [form] = Form.useForm();

  const [phoneChanged, setPhoneChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && planId && showModal)
      get<{ message: string; patient: Patient2 }>(
        "send/planWhatsappContent/" + planId
      )
        .then(r => {
          // console.log(r.data, "r sms co");
          form.resetFields();
          form.setFieldsValue({
            message: r.data.message,
            phone3:
              r.data.patient.phone && r.data.patient.phone?.length > 0
                ? r.data.patient.dial_code + r.data.patient.phone
                : {
                    countryCode: user?.settings.default_phone_dial_code ?? 44,
                    phoneNumber: ""
                  }
          });
        })
        .catch(_ => {});
  }, [planId, user, showModal]);

  const submit = (v: FormProps) => {
    setLoading(true);
    interface Res {
      successToast: string;
      credit_balance: number;
    }

    let data = {
      dial_code: v.phone3.countryCode,
      phone: v.phone3.areaCode + v.phone3.phoneNumber,
      message: v.message,
      save_phone: v.save_phone ?? false,
      plan_id: planId,
      patient_id: patientId
    };
    // return;
    post<Res>("send/whatsapp", data)
      .then(r => {
        if (user && r.data.credit_balance !== undefined)
          setUser({
            ...user,
            credit_balance: r.data.credit_balance
          });
        onDone();
      })
      .catch(_ => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MyModal
      title={t("whatsapp.title")}
      open={showModal}
      onCancel={onCancel}
      okText={t("send")}
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          })
          .finally(() => {});
      }}
    >
      <Form form={form} id="waForm" layout="vertical" autoComplete="off">
        <Form.Item label={t("phone")} name="phone3">
          <PhoneInput
            onInput={event => {
              let value = event.target.value;

              // Split the input value by spaces
              let parts = value.split(" ");

              // Check if the second part starts with '0' and remove it
              if (parts[1] && parts[1].charAt(0) === "0") {
                parts[1] = parts[1].slice(1);
              }

              // Join the parts back together and set the modified value
              event.target.value = parts.join(" ");
            }}
            enableSearch
            onChange={() => {
              setPhoneChanged(true);
            }}
          />
        </Form.Item>
        <Form.Item
          name="save_phone"
          valuePropName="checked"
          hidden={!phoneChanged}
          initialValue={true}
        >
          <Checkbox>{t("patient.savePhone")}</Checkbox>
        </Form.Item>
        <Form.Item
          name="message"

          label={t("sms.message")}
          rules={[
            {
              required: true
            }
          ]}
        >
          <ReactQuill readOnly  modules={{ toolbar: false }} />
        </Form.Item>

        <ValueLabel align="inline" label={t("sms.numberOfMessages")}>
          1
        </ValueLabel>
        <ValueLabel align="inline" label={t("account.yourCredit")}>
          {user?.credit_balance}
        </ValueLabel>
        {user && user.credit_balance < 60 && false && (
          <Alert
            className="margin-top"
            message="Warning Text Warning Text Warning TextW arning Text Warning Text Warning TextWarning Text"
            type="warning"
            closable
          />
        )}
        <Typography style={{ marginTop: 32 }}>
          <Title level={4}>{t("whatsapp.deliveryTitle")}</Title>
          <Paragraph>
            <Text>1. {t("whatsapp.terms1")}</Text>
          </Paragraph>
          <Paragraph>
            <Text>
              {t("whatsapp.terms2")}{" "}
              <Paragraph
                copyable={{
                  text: "https://wa.me/tos/20210210",
                  tooltips: [t("copy"), t("copied")]
                }}
              >
                https://wa.me/tos/20210210
              </Paragraph>
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>2. {t("whatsapp.checkPhone")}</Text>
          </Paragraph>
        </Typography>
      </Form>
    </MyModal>
  );
};

export default WhatsappModal;
