import React, { useEffect, useState } from "react";
import { Form, Input, Button, Skeleton, Alert } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { post } from "../../api/Request";
import queryString from "query-string";

import { useTranslation } from "react-i18next";
import AuthLayout from "./AuthLayout";
import MyPageHeader from "../../components/layout/MyPageHeader";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../../data/Constants";

interface AuthFormDTO {
  email: string;
  password: string;
}
const Login: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [mfaForm] = Form.useForm();
  // Access the "redirectTo" query parameter using the get method
  const redirectTo = searchParams.get("redirectTo");

  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [showLostAcess, setShowLostAcess] = useState(false);
  const [addCaptcha, setAddCaptcha] = useState(false);
  const [mfaRequired, setMfaRequired] = useState(false);

  const [captcha, setCaptcha] = useState("");

  const location = useLocation();

  const id = queryString.parse(location.search).id ?? "";
  const otp = queryString.parse(location.search).otp ?? "";

  useEffect(() => {
    if (id !== "") {
      setPageLoading(true);
      post("auth/login_as", {
        id: id
      })
        .then(r => {
          // console.log("user data", r.data);
          setUser(r.data);
          if (r.data.practitioner.require_password_change === true)
            return navigate("/update-password", { replace: true });

          if (r.data.clinics.length > 1)
            return navigate("/choose-clinic", { replace: true });
          else navigate("/patients", { replace: true });
        })
        .catch(_ => {})
        .finally(() => setPageLoading(false));
    } else setPageLoading(false);
    if (otp !== "") {
      setMfaRequired(true);
    }
  }, [id]);

  const submitLogin = (values: AuthFormDTO) => {
    setLoading(true);

    post("auth/login", {
      email: values.email,
      password: values.password,
      recaptcha: captcha
    })
      .then(r => {
        if (r.data.status === "mfa_required") {
          return setMfaRequired(true);
        }
        if (r.data.id === 1) {
          window.location.replace(config.OLD_SITE_URL + "admin/dashboard");
          return;
        }
        setUser(r.data);

        if (redirectTo) return navigate(redirectTo);

        if (r.data.practitioner.require_password_change === true)
          return navigate("/update-password", { replace: true });

        if (r.data.clinics.length > 1)
          navigate("/choose-clinic", { replace: true });
        else navigate("/patients", { replace: true });
      })
      .catch(e => {
        if (e.data && e.data.add_captcha === true) setAddCaptcha(true);
      })
      .finally(() => setLoading(false));
  };

  const submitOTP = () => {
    setLoading(true);
    post("auth/loginOtp", mfaForm.getFieldsValue())
      .then(r => {
        setUser(r.data);
        if (redirectTo) return navigate(redirectTo);

        if (r.data.practitioner.require_password_change === true)
          return navigate("/update-password", { replace: true });

        if (r.data.clinics.length > 1)
          navigate("/choose-clinic", { replace: true });
        else navigate("/patients", { replace: true });
      })
      .catch(_ => {
        setShowLostAcess(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user?.email) navigate("/patients", { replace: true });
  }, [user, navigate]);

  const title = <MyPageHeader title={t("auth.login")}></MyPageHeader>;
  return pageLoading ? (
    <Skeleton active />
  ) : (
    <AuthLayout title={title}>
      <div className="">
        <Form
          name="basic"
          hidden={mfaRequired}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={submitLogin}
          autoComplete="on"
        >
          <Form.Item
            label={t("email")}
            name="email"
            initialValue=""

            // rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input required type="email" />
          </Form.Item>

          <Form.Item
            label={t("auth.password")}
            name="password"
            initialValue=""
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>

          {addCaptcha && (
            <Form.Item
              name="recaptcha"
              label="reCAPTCHA"
              rules={[
                {
                  required: true,
                  message: "Please complete the reCAPTCHA!"
                }
              ]}
            >
              <ReCAPTCHA
                sitekey="6LcjDjkUAAAAAO1LVNpHpIGJG_jq02P2trjgNunC"
                onChange={c => setCaptcha(c)}
              />
            </Form.Item>
          )}

          <Form.Item style={{ marginTop: 32 }}>
            <Button loading={loading} type="primary" htmlType="submit" block>
              {t("auth.login")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button block onClick={() => navigate("/resetPassword/")}>
              {t("auth.forgotPassword")}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button block onClick={() => navigate("/register/")}>
              {t("auth.createAccount")}
            </Button>
          </Form.Item>
        </Form>

        <Form
          form={mfaForm}
          layout="vertical"
          onFinish={submitOTP}
          hidden={!mfaRequired}
        >
          {t("mfa.hint1")}
          <Form.Item
            className="margin-top-16"
            name="code"
            rules={[
              {
                required: true,
                message: t("mfa.hint1")
              }
            ]}
            label={t("mfa.otp")}
          >
            <Input
              type="number"
              step="1"
              maxLength={6}
              onChange={e => {
                if (e.target.value.length === 6) {
                  submitOTP();
                }
              }}
              placeholder={t("mfa.pleaseInputOtp")}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              // onClick={submitOTP}
              block
            >
              {t("auth.login")}
            </Button>
          </Form.Item>
          {showLostAcess && (
            <Alert
              message={t("mfa.lostAccess")}
              type="info"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
        </Form>
        <Button
          hidden={!mfaRequired}
          loading={loading}
          htmlType="button"
          block
          onClick={_ => {
            setMfaRequired(false);
          }}
        >
          {t("back")}
        </Button>
      </div>
    </AuthLayout>
  );
};

export default Login;
