import { Input, Select, Form, Checkbox, Tooltip, Alert } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanLayout } from "../../types/Plan";
import { get } from "../../api/Request";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MyModal from "../../components/MyModal";
interface Props {
  id: number;
  showModal: boolean;
  onDone: (id: number) => void;
  onCancel: () => void;
}

interface FormProps {
  subject: string;
  email:string;
  content: string;
}

const CustomNotesEmailModal: React.FC<Props> = ({
  id,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [formData, setFormData] = useState<FormProps>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(showModal && id)
    get<{ content: string; subject: string, email:string }>(
      "note/customEmailContent/" + id
    )
      .then(r => {
        setFormData({
          ...formData,
          subject: r.data.subject,
          content: r.data.content,
          email: r.data.email
        });
        form.setFieldsValue({
          ...formData,
          subject: r.data.subject,
          content: r.data.content,
          email: r.data.email
        });
      })
      .catch(_ => {});
  }, [id, showModal]);

  const submit = v => {
    // console.log("submit", v);
    setLoading(true);
    interface Res {
      id: number;
      message: string;
    }
    get<Res>("send/emailNote/" + id, v)
      .then(r => onDone(r.data.id))
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MyModal
      title={t("cn.emailNote")}
      open={showModal}
      onCancel={onCancel}
      okText={t("sendEmail")}
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            // form.resetFields();
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          })
          .finally(() => {});
      }}
    >
      <Form
        form={form}
        id="customEmailForm"
        // initialValues={initialData}
        layout="vertical"
        autoComplete="off"
        initialValues={formData}
      >
        <Form.Item
          label={t("plan.emailSubject")}
          name="subject"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("email")}
          name="email"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="content"
          label={t("plan.emailBody")}
          rules={[
            {
              required: true
            }
          ]}
        >
          <ReactQuill theme="snow" modules={{ toolbar: false }}/>
        </Form.Item>
      
      </Form>
      <Alert
        className="margin-bottom"
        message={t("cn.emailNoteHint")}
        type="info"
        closable />
    </MyModal>
  );
};

export default CustomNotesEmailModal;
