import { Input, Select, Form, Checkbox, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanLayout } from "../../types/Plan";
import { get } from "../../api/Request";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MyModal from "../../components/MyModal";
interface Props {
  planId: number;
  planName?: string;
  showModal: boolean;
  onDone: (id: number) => void;
  onCancel: () => void;
}

interface FormProps {
  subject: string;
  content: string;
  attach_pdf?: string;
  attach_diary?: boolean;
}

const CustomEmailModal: React.FC<Props> = ({
  planId,
  planName,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const [form] = Form.useForm();

  const [formData, setFormData] = useState<FormProps>();
  const [loading, setLoading] = useState(false);
  const [showAttachDiary, setShowAttachDiary] = useState(false);

  useEffect(() => {
    get<{ content: string; subject: string }>(
      "plan/customEmailContent/" + planId
    )
      .then(r => {
        setFormData({
          ...formData,
          subject: r.data.subject,
          content: r.data.content,
          attach_pdf: PlanLayout.default,
          attach_diary: false
        });
        form.setFieldsValue({
          ...formData,
          subject: r.data.subject,
          content: r.data.content,
          attach_pdf: PlanLayout.default,
          attach_diary: false
        });
      })
      .catch(_ => {});
  }, [planId]);

  const submit = v => {
    // console.log("submit", v);
    setLoading(true);
    interface Res {
      id: number;
      message: string;
    }
    get<Res>("send/emailPlan/" + planId, v)
      .then(r => onDone(r.data.id))
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MyModal
      title={t("plan.customEmail")}
      open={showModal}
      onCancel={onCancel}
      okText={t("sendEmail")}
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            // form.resetFields();
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          })
          .finally(() => {});
      }}
    >
      <Form
        form={form}
        id="customEmailForm"
        // initialValues={initialData}
        layout="vertical"
        autoComplete="off"
        initialValues={formData}
      >
        <Form.Item
          label={t("plan.emailSubject")}
          name="subject"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item

          name="content"
          label={t("plan.emailBody")}
          rules={[
            {
              required: true
            }
          ]}
        >
          <ReactQuill theme="snow"  modules={{ toolbar: false }}/>
        </Form.Item>
        <Form.Item
          name="attach_pdf"
          label={t("plan.emailAttachPdf")}
          initialValue=""
        >
          <Select
            style={{ width: "100%" }}
            onChange={v => {
              v ? setShowAttachDiary(true) : setShowAttachDiary(false);
            }}
          >
            <Option value="">{t("none")}</Option>
            <Option value={PlanLayout.default}>
              {t("planLayout." + PlanLayout.default)}
            </Option>
            <Option value={PlanLayout.largeImage}>
              {t("planLayout." + PlanLayout.largeImage)}
            </Option>
            <Option value={PlanLayout.superLarge}>
              {t("planLayout." + PlanLayout.superLarge)}
            </Option>
            <Option value={PlanLayout.compressed}>
              {t("planLayout." + PlanLayout.compressed)}
            </Option>
            <Option value={PlanLayout.summary}>
              {t("planLayout." + PlanLayout.summary)}
            </Option>
            <Option value={PlanLayout.twoCol}>
              {t("planLayout." + PlanLayout.twoCol)}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="attach_diary"
          valuePropName="checked"
          hidden={!showAttachDiary}
          initialValue={showAttachDiary}
        >
          <Checkbox>
            {t("form.attachDiary")}{" "}
            <Tooltip title={t("form.attachDiaryHint")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Checkbox>
        </Form.Item>
        {/* <Form.Item
          label={t("form.exerciseImageType")}
          name="image_type"
          initialValue={imageType}
        >
          <Radio.Group
            options={exerciseImageType}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item> */}

        {/* {initialData.id === 0 && (
          <Form.Item
            tooltip={t("help.patientConsent")}
            name="patient_consent"
            rules={[
              {
                required: true,
                validator: checkCheckBox
              }
            ]}
            valuePropName="checked"
          >
            <Checkbox>{t("form.patientConsent")}</Checkbox>
          </Form.Item>
        )} */}
      </Form>
    </MyModal>
  );
};

export default CustomEmailModal;
