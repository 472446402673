import { Input, Form, Select, Button, Space } from "antd";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { post } from "../../api/Request";
import { ApiRes } from "../../types/General";
import MyModal from "../../components/MyModal";
import { ClinicalNoteTemplateUpsert } from "../../types/Patient";
import ReactQuill from "react-quill";

interface Props {
  template: ClinicalNoteTemplateUpsert;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

const ClinicalNoteTemplateModal: React.FC<Props> = ({
  template,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const quillRef = useRef<ReactQuill | null>(null); // Reference to ReactQuill
  const [quillRange, setQuillRange] = useState<any>(null); // Store the cursor position

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...template
    });
  }, [template, form]);
  const [loading, setLoading] = useState(false);

  const submit = v => {
    setLoading(true);

    post<ApiRes>("note/upsertTemplate", v)
      .then(r => {
        onDone();
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  // Function to insert HTML at the stored cursor position
  const insertHTML = (html: string) => {
    const editor = quillRef.current?.getEditor(); // Access the Quill editor instance
    if (quillRange) {
      const { index, length } = quillRange; // Get the selection range

      if (length > 0 && editor) {
        // If there's a selection, remove the selected text
        editor.deleteText(index, length);
      }
    }

    if (quillRange && quillRange.index !== null) {
      // If the cursor position is stored, insert HTML at that position
      editor?.clipboard.dangerouslyPasteHTML(quillRange.index, html);
    } else {
      // If no cursor is stored, append the HTML at the end
      editor?.clipboard.dangerouslyPasteHTML(
        editor.getLength(),
        "<br />" + html
      );
    }
  };

  return (
    <MyModal
      title={template.id > 0 ? t("template.edit") : t("template.createNew")}
      open={showModal}
      onCancel={onCancel}
      okText={t("save")}
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          })
          .finally(() => {});
      }}
    >
      {template && (
        <>
          <Form form={form} id="smsForm" layout="vertical" autoComplete="off">
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>

            <Form.Item
              label={t("template.category")}
              name="category"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: "quick", label: t("cn.quick") },
                  { value: "rf", label: t("cn.rf") },
                  { value: "template", label: t("cn.tmplts") }
                ]}
              />
            </Form.Item>

            <Form.Item
              label={t("template.templateName")}
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Space size={[4, 4]} wrap style={{ marginBottom: 8 }}>
              <Button
                key={"insertSigDate"}
                onClick={() => {
                  insertHTML("[!PRACTITIONER_NAME!]");
                }}
              >
                {t("cn.pName")}
              </Button>
              <Button
                key={"date"}
                onClick={() => {
                  insertHTML("[!DATE!]");
                }}
              >
                {t("date")}
              </Button>
              <Button
                key={"pname"}
                onClick={() => {
                  insertHTML("[!PATIENT_NAME!]");
                }}
              >
                {t("planUpsert.patientName")}
              </Button>
            </Space>
            <Form.Item
              name="template"
              label={t("cn.text")}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <ReactQuill
                className="full_quill"
                ref={quillRef}
                onKeyUp={() => {}}
                onChangeSelection={e => {
                  console.log(e, "cs");
                  if (e !== null) setQuillRange(e);
                }}
                theme="snow"
                style={{ height: "300px", marginBottom: 16 }}
              />
            </Form.Item>
          </Form>
        </>
      )}
    </MyModal>
  );
};

export default ClinicalNoteTemplateModal;
